<script setup lang="ts">
import { Container, Draggable } from 'vue-dndrop';

import { useAuth } from '@/hooks/useAuth';
import { useDragAndDrop } from '@/hooks/useDragAndDrop';

import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import SurveyBlockCard from '@/components/surveys/surveys/SurveyBlockCard.vue';

const props = defineProps<{
  blocks: App.Surveys.Data.SurveyBlockData[];
  selectedBlock?: number;
  survey: App.Surveys.Data.SurveyData;
}>();

const emit = defineEmits<{
  onClick: [value: number];
  addQuestion: [value: number | null];
}>();

const { hasPermission } = useAuth();

const addingQuestion = ref(false);

const { dragAndDropItems, onDrop, applyDrag } = useDragAndDrop<App.Surveys.Data.SurveyBlockData>(
  props.blocks
);

function handleAddQuestionClick(blockId: number | null) {
  addingQuestion.value = true;
  emit('addQuestion', blockId);
}

function handleBlockOrderChange(event) {
  onDrop(event);

  const newOrderedBlocks = applyDrag(props.blocks, event);

  useForm({
    method: 'PUT',
    preserveState: false,
    url: route('surveys.blocks.order.update', {
      survey: props.survey.id
    }),
    fields: {
      block_ids: newOrderedBlocks.map((block) => block.id)
    },
    hooks: {
      success() {
        router.reload({ only: ['survey'] });
      }
    }
  }).submit();
}
</script>

<template>
  <div class="divide-y-slate-200 flex w-full flex-col divide-y">
    <div class="px-4">
      <h2 class="grow py-5 text-2xs font-bold uppercase leading-4 text-slate-600">Question List</h2>
    </div>
    <template v-if="dragAndDropItems.length">
      <Container
        @drop="handleBlockOrderChange"
        lockAxis="y"
        dragHandleSelector=".block-card-handle"
        class="mx-4 flex flex-1 flex-col gap-y-2 overflow-y-auto py-6"
      >
        <Draggable v-for="block in dragAndDropItems" :key="block.id" class="shrink-0">
          <SurveyBlockCard
            :block="block"
            :selected="block.id === selectedBlock"
            :survey="survey"
            @onAddQuestionClick="handleAddQuestionClick"
          />
        </Draggable>
      </Container>
    </template>
    <div v-else class="px-4 py-6">
      <EmptyCard
        isDashed
        isRounded
        title="No questions yet"
        size="md"
        variant="invisible"
        :description="
          hasPermission('update-survey')
            ? 'Start building your survey by adding questions'
            : 'This survey is a work in progress.'
        "
        :actionLabel="hasPermission('update-survey') ? 'Add question' : ''"
        :isLoading="addingQuestion"
        @actionClick="handleAddQuestionClick"
      />
    </div>
  </div>
</template>
